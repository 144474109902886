const successMessageDictionary = {
  user: "Пользователь",
  users: "Пользователи",
  userGroup: "Группа пользователей",
  staff: "Техник",
  serviceTask: "Заявка",
  serviceGroup: "Группа объектов",
  serviceOrganization: "Сервисная организация",
  admin: "Администратор",
  profile: "Профиль",
  profileNumber: "Номер",
  personnel: "Сотрудник",
  personnels: "Сотрудники",
  role: "Роль",
  parent: "Опекун",
  object: "Объект",
  account: "Лицевой счет",
  card: "Карта",
  cards: "Карты",
  message: "Сообщение",
  kindergarten: "Детский сад",
  firmwares: "Прошивка",
  dealer: "Дилер",
  controller: "Контроллер",
  company: "Компания",
  children: "Воспитанник",
  childrens: "Воспитанники",
  childrenGroup: "Детская группа",
  manager: "Воспитатель",
  promocode: "Промокод",
  payment: "Платеж",
  codes: "Запрос",
  hash: "Кеш",
  access: "Доступ"
};

function checkType(name) {
  let ending = "";
  if (
    name == "Группа пользователей" ||
    name == "Заявка" ||
    name == "Группа объектов" ||
    name == "Сервисная организация" ||
    name == "Карта" ||
    name == "Прошивка" ||
    name == "Компания" ||
    name == "Детская группа"
  ) {
    ending = "а";
  }

  if (name == "Сообщение") {
    ending = "о";
  }

  if (name == "Карты" || name == "Пользователи" || name == "Воспитанники") {
    ending = "ы";
  }

  return ending;
}

const actionMessage = {
  add: function(name) {
    if (name == "Администратор") {
      return `Пользователь добавлен в администраторы!`;
    } else {
      return `${name} добавлен${checkType(name)}!`;
    }
  },
  remove: function(name) {
    if (name == "Администратор") {
      return `Пользователь удален из администраторов!`;
    } else {
      return `${name} удален${checkType(name)}!`;
    }
  },
  refund: function(name) {
    return `${name} возвращен`;
  },
  edit: function(name) {
    return `${name} изменен${checkType(name)}!`;
  },
  ban: function(name) {
    return `${name} заблокирован${checkType(name)}!`;
  },
  unBan: function(name) {
    return `${name} разблокирован${checkType(name)}!`;
  },
  send: function(name) {
    return `${name} отправлен${checkType(name)}!`;
  },
  upload: function(name) {
    return `${name} загружены!`;
  },
  transfer: function(name) {
    return `${name} перенесен`;
  },
  change: function(name) {
    return `${name} изменена!`;
  },
  activated: function(name) {
    return `${name} активирована!`;
  },
  restore: function(name) {
    return `${name} восстановлен${checkType(name)}!`;
  }
};

export { actionMessage, successMessageDictionary };
