import Vue from "vue";
import VueMq from "vue-mq";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { initValidation } from "@/helpers/Validation";
import { initAxios } from "@/helpers/axios";
import vuetify from "./plugins/vuetify";
import VueTheMask from "vue-the-mask";
import autoIdQueries from "@/mixins/autoIdQueries";
import "@/style/scss/style.scss";
import "@/style/scss/adaptive.scss";
import * as Sentry from "@sentry/vue";
import VueCookies from "vue-cookies";

import Fragment from "vue-fragment";
import moment from "moment";
import "moment/locale/ru";

import phoneFilter from "@/helpers/filters/phoneFilter";
import numberRounded from "@/helpers/filters/numberRounded";

import momentDurationFormatSetup from "moment-duration-format";

import VueEvents from "vue-events";
import VCheckbox from "vuetify/lib/components/VCheckbox/";
Vue.component("v-checkbox", VCheckbox);
console.log(`App revision: ${process.env.VUE_APP_GIT_HASH}`);

Vue.prototype.$leftJoin = function(objArr1, objArr2, key1, key2) {
  return objArr1.map(anObj1 => ({
    ...objArr2.find(anObj2 => anObj1[key1] === anObj2[key2]),
    ...anObj1
  }));
};

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router)
      }),
      new Sentry.Replay()
    ],
    tracesSampleRate: 1.0,
    tracePropagationTargets: [process.env.VUE_APP_BASE_URL],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    environment: process.env.NODE_ENV,
    release: process.env.VUE_APP_GIT_HASH
  });
}

Array.prototype.$insert = function(index, item) {
  this.splice(index, 0, item);
};

Vue.filter(phoneFilter.name, phoneFilter.execute);
Vue.filter(numberRounded.name, numberRounded.execute);
momentDurationFormatSetup(moment);
moment.locale("ru");

Vue.use(VueEvents);

Vue.use(VueCookies);
// window.moment = moment;
Vue.use(Fragment.Plugin);

Vue.mixin(autoIdQueries);

Vue.use(VueTheMask);
Vue.use(VueMq, {
  breakpoints: {
    mobile: 768, // Значение может быть другим, в зависимости от ваших потребностей
    tablet: 1024,
    laptop: 1280,
    desktop: Infinity
  }
});
Vue.config.productionTip = false;

initValidation();
initAxios();

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");
