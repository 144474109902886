import {
  getAccessesRequest,
  restoreUserAccessRequest
} from "@/helpers/api/Access";

const state = {
  pageAccessList: {
    count: 0,
    next: null,
    previous: null,
    results: []
  },
  selectedUserId: null
};

const getters = {
  getPageAccessList: state => state.pageAccessList,
  selectedUserId: state => state.selectedUserId
};
const actions = {
  async restoreUserAccess(context, { userId, authGroupId }) {
    const data = {
      user_id: userId,
      auth_group_id: authGroupId
    };
    return new Promise((resolve, reject) => {
      restoreUserAccessRequest({ data })
        .then(response => {
          context.commit("setAccessList", {
            listName: "pageAccessList",
            access: response.data
          });
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  fetchPageAccessList(context) {
    const query = context.getters.getPageQuery.query;
    const ordering = context.getters.getOrdering;
    return new Promise((resolve, reject) => {
      getAccessesRequest({
        query: {
          ordering,

          ...query,
          "optional-fields": ["card_details", "user_details"]
        }
      })
        .then(response => {
          context.commit("setAccessList", {
            listName: "pageAccessList",
            access: response.data
          });
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  fetchPageAccessListFromUser(context) {
    const query = context.getters.getPageQuery.query;
    const ordering = context.getters.getOrdering;
    let id = context.getters.getPageQuery.id;
    return new Promise((resolve, reject) => {
      getAccessesRequest({
        query: {
          ordering,
          user: id,
          ...query,
          "optional-fields": ["card_details", "user_details"]
        }
      })
        .then(response => {
          context.commit("setAccessList", {
            listName: "pageAccessList",
            access: response.data
          });
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};

const mutations = {
  setAccessList(state, { listName, access }) {
    state[listName] = access;
  },
  SET_SELECTED_USER_ID(state, id) {
    state.selectedUserId = id;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
