import store from "@/store";
import { getToken, removeToken } from "@/helpers/jwtHelper";
import Axios from "axios";
import * as authRequests from "@/helpers/api/auth";

let isTokenRefreshing = false;
let refreshSubscribers = [];

function subscribeTokenRefresh() {
  return new Promise(resolve => refreshSubscribers.push(resolve));
}

function onRefreshed(newToken) {
  refreshSubscribers.forEach(callback => callback(newToken));
  refreshSubscribers = [];
}

async function refreshAccessToken() {
  const { refresh, refresh_expires, user } = getToken();

  if (refresh_expires * 1000 < Date.now()) {
    store.commit("logout");
    removeToken();
    return Promise.reject("Refresh token expired");
  }

  try {
    const response = await authRequests.refreshTokenRequest({ refresh });
    const { access, expires, now } = response.data;
    const newToken = { access, expires, now, refresh, refresh_expires, user };

    store.commit("setToken", { token: newToken });
    onRefreshed(newToken);

    return newToken;
  } catch (error) {
    store.commit("logout");
    removeToken();
    return Promise.reject(error);
  }
}

function axiosInterceptor() {
  Axios.interceptors.request.use(
    async config => {
      const token = getToken();
      if (token) {
        config.headers["Authorization"] = `Bearer ${token.access}`;
      }
      return config;
    },
    error => Promise.reject(error)
  );

  Axios.interceptors.response.use(
    response => response,
    async error => {
      const originalRequest = error.config;
      if (
        error.response &&
        error.response?.status === 401 &&
        !originalRequest._retry
      ) {
        originalRequest._retry = true;

        if (isTokenRefreshing) {
          return subscribeTokenRefresh().then(newToken => {
            originalRequest.headers[
              "Authorization"
            ] = `Bearer ${newToken.access}`;
            return Axios(originalRequest);
          });
        }

        isTokenRefreshing = true;

        try {
          const newToken = await refreshAccessToken();
          originalRequest.headers[
            "Authorization"
          ] = `Bearer ${newToken.access}`;
          onRefreshed(newToken);
          return Axios(originalRequest);
        } catch (e) {
          return Promise.reject(e);
        } finally {
          isTokenRefreshing = false;
        }
      } else if (
        error.response.config.url === "/auth/refresh-token/" &&
        ((error.response.status === 401 &&
          error.response.data.code === "authentication_failed") ||
          (error.response.status === 403 &&
            error.response.data.code === "permission-denied"))
      ) {
        store.dispatch("errorNotification", {
          errors: [
            {
              field_verbose: "Ошибка",
              message: "Выполнен вход с другого устройства"
            }
          ]
        });
        store.commit("logout");
        removeToken();
      } else if (
        (error.response &&
          error.response.status === 403 &&
          error.response.data.code === "permission-denied" &&
          error.response.data.code !==
            "Данный метод доступен только неавторизованным пользователям" &&
          error.response.data.detail !==
            "Невозможно авторизоваться с указанными данными" &&
          error.response.data.detail !==
            "У вас недостаточно прав для данного действия" &&
          error.response.data.detail !==
            "Невозможно предоставить доступ на этот объект. У пользователя нет к нему доступа.") ||
        (error.response.status === 401 &&
          error.response.data.code !== "token_not_valid" &&
          error.response.data.code != "users-06" &&
          error.response.data.code != "users-05")
      ) {
        try {
          const newToken = await refreshAccessToken();
          const { url, method, baseURL } = error.config;
          error.config.headers["Authorization"] = `Bearer ${newToken.access}`;
          return Axios.request({
            url,
            method,
            baseURL,
            headers: error.config.headers
          });
        } catch (error) {
          return Promise.reject(error);
        }
      } else if (error.response && error.response.status === 500) {
        store.dispatch("errorNotification", {
          errors: error.response.data.errors
            ? error.response.data.errors
            : [
                {
                  field_verbose: "   ",
                  message: "Ошибка при обращении на сервер!"
                }
              ]
        });
        return Promise.reject(error);
      } else if (
        (error.response && error.response.status === 400) ||
        error.response.status === 404
      ) {
        store.dispatch("errorNotification", {
          errors: error.response.data.errors
            ? error.response.data.errors
            : [
                {
                  message: "Ошибка при получении данных"
                }
              ]
        });
        return Promise.reject(error);
      } else {
        store.dispatch("errorNotification", {
          errors: error.response.data.errors
            ? error.response.data.errors
            : [
                {
                  field_verbose: "Ошибка",
                  message: error.response.data.message
                    ? error.response.data.message
                    : error.response.data.detail
                }
              ]
        });
        return Promise.reject(error);
      }
    }
  );
}

export async function initAxios() {
  const nonEnvBaseUrl =
    window.location.protocol === "https:"
      ? "https://portal.oppen.ru/api/v1/"
      : "http://dev.portal.oppen.ru/api/v1/";

  Axios.defaults.baseURL = process.env.VUE_APP_API_URL || nonEnvBaseUrl;

  const token = getToken();
  const timeNow = Date.now();

  axiosInterceptor();

  try {
    if (token) {
      if (token.expires * 1000 > timeNow) {
        store.commit("setToken", { token: token });
      }
    } else {
      store.commit("logout");
      removeToken();
    }
  } catch (e) {
    store.commit("logout");
    removeToken();
  }
}
