import * as authRequests from "@/helpers/api/auth";
import { getToken, removeToken, saveToken } from "@/helpers/jwtHelper";
import Axios from "axios";
import router from "@/router";
import { getUserByIdRequest } from "@/helpers/api/user";
import { YMUserIdMetcrik, YMLoginMetrik } from "@/plugins/yandex_metrika";
import * as Sentry from "@sentry/vue";
// import {registrationRequest} from "@/helpers/api/auth";

const state = {
  token: getToken(),
  tempToken: null,
  currentUser: null,
  captchaUrl: null,
  captcha: null,

  loginForm: {
    phone: {
      value: "",
      error: null
    },
    password: {
      value: "",
      error: null
    }
  },
  registrationForm: {
    status: 0,
    phone: {
      value: "",
      error: null
    },
    code: {
      value: "",
      error: null
    },
    searchCity: {
      value: "",
      error: null
    },
    city: {
      value: null,
      error: null
    },
    searchObject: {
      value: "",
      error: null
    },
    object: {
      value: null,
      error: null
    },
    account: {
      value: null,
      error: null
    },
    password: {
      value: "",
      error: null
    },
    repeatPassword: {
      value: "",
      error: null
    }
  },
  recoveryForm: {
    status: 0,

    phone: {
      value: "",
      error: null
    },
    code: {
      value: "",
      error: null
    },
    password: {
      value: "",
      error: null
    },
    repeatPassword: {
      value: "",
      error: null
    }
  }
};

const getters = {
  getTempToken: state => state.tempToken,
  getLoginForm: state => state.loginForm,
  getRegistrationForm: state => state.registrationForm,
  getRecoveryForm: state => state.recoveryForm,
  isAuthorized: state => !!state.token,
  getCurrentUser: state => state.currentUser,
  getCaptcha: state => state.captchaUrl,
  getCaptchaValue: state => state.captcha,
  getUserId: state => (state.token ? state.token.user : null)
};

const actions = {
  async fetchCaptcha(context) {
    try {
      const response = await Axios.post("/captcha/", {
        phone: context.state.registrationForm.phone.value
          .replace(/[^\d+]/g, "")
          .slice(0, 13)
      });
      context.commit("setCaptcha", response.data);
    } catch (error) {
      console.error("Error fetching captcha:", error);
    }
  },
  async login(context) {
    return new Promise((resolve, reject) => {
      authRequests
        .loginRequest({
          phone: context.state.loginForm.phone.value
            .replace(/[^\d+]/g, "")
            .slice(0, 13),
          password: context.state.loginForm.password.value
        })
        .then(response => {
          const { token, user } = response.data;
          context.commit("setToken", { token: { ...token, user: user.id } });
          context.commit("setUser", { user });
          YMLoginMetrik("login");
          resolve();
          router.push("/");
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  async startRecovery(context, type) {
    const recoveryForm = context.getters.getRecoveryForm;
    return new Promise((resolve, reject) => {
      authRequests
        .passwordResetRequest({
          phone: recoveryForm.phone.value.replace(/[^\d+]/g, "").slice(0, 13),
          code_type: type ? type.type : "phone_numbers"
        })
        .then(response => {
          const { temp_token, expires, repeat, now } = response.data;
          context.commit("setTempToken", { temp_token, expires, repeat, now });
          context.commit("setFormStatus", {
            formName: "recoveryForm",
            code: 1
          });
          router.push({ name: "CodeRecovery" });
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  async checkRecoveryCode(context) {
    const recoveryForm = context.getters.getRecoveryForm;
    return new Promise((resolve, reject) => {
      if (recoveryForm.status > 0)
        authRequests
          .checkRegistrationCodeRequest({
            temp_token: context.state.tempToken.temp_token,
            code: recoveryForm.code.value
          })
          // eslint-disable-next-line no-unused-vars
          .then(response => {
            context.commit("setFormStatus", {
              formName: "recoveryForm",
              code: 2
            });
            router.push({ name: "FinishRecovery" });
            resolve();
          })
          .catch(err => {
            reject(err);
          });
    });
  },
  async finishRecovery(context) {
    const recoveryForm = context.getters.getRecoveryForm;
    return new Promise((resolve, reject) => {
      if (recoveryForm.status > 1)
        authRequests
          .passwordResetConfirmRequest({
            temp_token: context.state.tempToken.temp_token,
            code: recoveryForm.code.value,
            password: recoveryForm.password.value
          })
          .then(response => {
            const { token, user } = response.data;
            context.commit("setToken", { token: { ...token, user: user.id } });
            context.commit("setUser", { user });
            context.commit("setFormStatus", {
              formName: "recoveryForm",
              code: 0
            });
            router.push("/");
            resolve();
          })
          .catch(err => {
            reject(err);
          });
    });
  },
  // async startRegistration(context, { type, captcha }) {
  //   try {
  //     const phone = context.state.registrationForm.phone.value.replace(
  //       /[^\d+]/g,
  //       ""
  //     );
  //
  //     // Добавляем в запрос капчу
  //     const response = await authRequests.registrationRequest({
  //       phone,
  //       code_type: type ? type.type : "phone_numbers",
  //       captcha
  //     });
  //
  //     if (response.data) {
  //       const { temp_token, expires, repeat, now } = response.data;
  //
  //       // Обновляем состояние приложения
  //       context.commit("setTempToken", { temp_token, expires, repeat, now });
  //       context.commit("setFormStatus", {
  //         formName: "registrationForm",
  //         code: 5
  //       });
  //
  //       // Перенаправляем пользователя на страницу "CodeRegistration"
  //       router.push({ name: "CodeRegistration" });
  //     }
  //
  //     return response; // Возвращаем ответ от сервера
  //   } catch (error) {
  //     if (error.response && error.response.data.code === "users-05") {
  //       context.commit("setFormStatus", {
  //         formName: "registrationForm",
  //         code: 1
  //       });
  //       router.push({ name: "CityRegistration" });
  //     }
  //
  //     throw error;
  //   }
  // },
  async startRegistration(context, type) {
    return new Promise((resolve, reject) => {
      authRequests
        .registrationRequest({
          phone: context.state.registrationForm.phone.value.replace(
            /[^\d+]/g,
            ""
          ),
          captcha: context.getters.getCaptchaValue.value,
          code_type: type ? type.type : "phone_numbers"
        })
        .then(response => {
          if (response.data) {
            const { temp_token, expires, repeat, now } = response.data;
            context.commit("setTempToken", {
              temp_token,
              expires,
              repeat,
              now
            });

            context.commit("setFormStatus", {
              formName: "registrationForm",
              code: 5
            });
            router.push({ name: "CodeRegistration" });
            resolve();
          }
        })
        .catch(error => {
          console.log(error);
          context.dispatch("fetchCaptcha");
          if (error.response) {
            if (error.response.data.code === "users-05") {
              context.commit("setFormStatus", {
                formName: "registrationForm",
                code: 1
              });
              router.push({ name: "CityRegistration" });
              resolve();
            }
          }
          reject(error);
        });
    });
  },
  async selectCity(context, { id }) {
    context.commit("setFormValue", {
      formName: "registrationForm",
      field: "city",
      value: id
    });
    context.commit("setFormStatus", {
      formName: "registrationForm",
      code: 2
    });
    router.push({ name: "ObjectRegistration" });
  },
  async selectObjectAddress(context, { id }) {
    context.commit("setFormValue", {
      formName: "registrationForm",
      field: "object",
      value: id
    });
    context.commit("setFormStatus", {
      formName: "registrationForm",
      code: 3
    });
    router.push({ name: "AccountRegistration" });
  },
  async checkAccount(context) {
    context.commit("setFormStatus", {
      formName: "registrationForm",
      code: 4
    });
    router.push({ name: "CheckPhoneRegistration" });
  },
  async registrationByAccount(context) {
    const registrationForm = context.getters.getRegistrationForm;
    return new Promise((resolve, reject) => {
      authRequests
        .registrationByAccountRequest({
          object_id: registrationForm.object.value,
          account: registrationForm.account.value.replace(/[^\d]/g, ""),
          phone: registrationForm.phone.value
            .replace(/[^\d+]/g, "")
            .slice(0, 13),
          code_type: "phone_numbers"
        })
        .then(response => {
          const { temp_token, expires, repeat, now } = response.data;
          context.commit("setTempToken", { temp_token, expires, repeat, now });

          context.commit("setFormStatus", {
            formName: "registrationForm",
            code: 5
          });
          router.push({ name: "CodeRegistration" });
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  async checkRegistrationCode(context) {
    return new Promise((resolve, reject) => {
      authRequests
        .registrationConfirmRegistrationCodeRequest({
          temp_token: context.state.tempToken.temp_token,
          code: context.getters.getRegistrationForm.code.value
        })
        .then(response => {
          const { temp_token, expires, repeat, now } = response.data;
          context.commit("setTempToken", { temp_token, expires, repeat, now });
          context.commit("setFormStatus", {
            formName: "registrationForm",
            code: 6
          });
          router.push({ name: "FinishRegistration" });
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  async finishRegistration(context) {
    return new Promise((resolve, reject) => {
      authRequests
        .registrationCompleteRequest({
          temp_token: context.getters.getTempToken.temp_token,
          password: context.getters.getRegistrationForm.password.value
        })
        .then(response => {
          const { token, user } = response.data;
          context.commit("setToken", { token: { ...token, user: user.id } });
          context.commit("setUser", { user });
          context.commit("setFormStatus", {
            formName: "registrationForm",
            code: 0
          });
          router.push("/");
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  async logout(context) {
    return new Promise(() => {
      authRequests
        .logoutRequest({ userId: context.getters.getUserId })
        .finally(() => {
          context.commit("logout");
        });
    });
  },
  async fetchCurrentUser(context) {
    return new Promise((resolve, reject) => {
      const currentUserId = context.getters.getUserId;
      getUserByIdRequest({ id: currentUserId })
        .then(res => {
          context.commit("setUser", { user: res.data });
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  }
};

const mutations = {
  setCaptcha: (state, captchaUrl) => {
    state.captchaUrl = captchaUrl;
  },
  setCaptchaValue: (state, captcha) => {
    state.captcha = captcha;
  },
  setFormStatus(state, { formName, code }) {
    state[formName].status = code;
  },
  setTempToken(state, { temp_token, expires, repeat, now }) {
    state.tempToken = { temp_token, expires, repeat, now };
  },
  setFormValue(state, { formName, field, value }) {
    state[formName][field].value = value;
  },
  setUser(state, { user }) {
    state.currentUser = user;

    if (process.env.NODE_ENV === "production") {
      Sentry.setUser({ id: user.id });
    }
  },
  setToken(state, { token }) {
    Axios.defaults.headers["Authorization"] = `Bearer ${token.access}`;
    state.token = token;
    saveToken(token);
    YMUserIdMetcrik(token.user);
  },
  logout(state) {
    state.currentUser = null;
    state.token = null;
    removeToken();
    Axios.defaults.headers["Authorization"] = "";
    router.push({ name: "Login" });
    if (process.env.NODE_ENV === "production") {
      Sentry.setUser(null);
    }
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
